<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          style="width: 140px"
          placeholder="请输入任务名称"
          autocomplete="off"
          size="small"
          clearable
          v-model="taskName"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">教育机构</label>
        <el-input
          style="width: 160px"
          placeholder="请输入教育机构"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchTitle"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">返款状态</label>
        <el-select
          size="small"
          clearable
          v-model="searchIsReturn"
          placeholder="全部"
          style="width: 120px"
        >
          <el-option
            v-for="item in IsReturnList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集时间</label>
        <el-date-picker
          clearable
          style="width: 140px"
          type="date"
          placeholder="开始时间"
          autocomplete="off"
          size="small"
          v-model="searchBeginCollect"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          clearable
          type="date"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 140px"
          v-model="searchEndCollect"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <label class="fn-14">返款时间</label>
        <el-date-picker
          clearable
          style="width: 160px"
          type="date"
          placeholder="开始时间"
          autocomplete="off"
          size="small"
          v-model="searchBeginReturn"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          clearable
          type="date"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
          v-model="searchEndReturn"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <label class="fn-14">票据号</label>
        <el-input
          style="width: 130px"
          placeholder="请输入票据号"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchBillNo"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">摄影师</label>
        <el-input
          style="width: 130px"
          placeholder="请输入摄影师"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchPhotographer"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">返款金额</label>
        <el-input
          style="width: 120px"
          placeholder="最小金额"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchMixReturn"
        ></el-input>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-input
          style="width: 120px"
          placeholder="最大金额"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchMaxReturn"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">所属批次</label>
        <el-input
          style="width: 180px"
          placeholder="请输入批次名称"
          autocomplete="off"
          size="small"
          clearable
          v-model="searchBatchName"
        ></el-input>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          v-if="searchButton"
        >
          查询
        </el-button>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="handleDownload"
          :loading="DownloadLoading"
          size="small"
          v-if="DownloadButton"
        >
          导出
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: auto">
      <el-table
        :data="ticketList"
        v-loading="ticketLoading"
        stripe
        border
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ ticketLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="title" label="任务名称" width="280" fixed>
        </el-table-column>
        <el-table-column prop="company" label="返款单位" width="240" align="center" fixed>
        </el-table-column>

        <el-table-column prop="contact" label="联系人" width="120" align="center" fixed>
          <template slot-scope="scope">
            <span>{{ scope.row.contact }}</span></template
          >
        </el-table-column>
        <el-table-column
          prop="contactPhone"
          label="联系电话"
          width="150"
          align="center"
          fixed
        >
          <template slot-scope="scope">
            <span>{{ scope.row.contactPhone }}</span></template
          >
        </el-table-column>
        <el-table-column
          prop="photographer"
          label="摄影师"
          width="150"
          align="center"
          fixed
        >
        </el-table-column>
        <el-table-column
          prop="endDate"
          :formatter="columnDateFormat"
          label="采集时间"
          width="180"
          align="center"
          fixed
        >
        </el-table-column>
        <el-table-column
          prop="totalCount"
          label="采集人数"
          width="80"
          align="center"
          fixed
        >
        </el-table-column>
        <el-table-column
          prop="accountsPayable"
          label="应交款"
          width="120"
          align="center"
          fixed
        >
          <template slot-scope="scope">
            <span>{{ Number(scope.row.accountsPayable).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="collectionTime"
          :formatter="columnDateFormat"
          label="交款时间"
          width="180"
          align="center"
          fixed
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.collectionTime">未收款</span>
            <span v-else>{{ formDateFormat(scope.row.collectionTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="billTime"
          :formatter="columnDateFormat"
          label="财务入账时间"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.billTime">暂无入账</span>
            <span v-else>{{ formDateFormat(scope.row.billTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="batchReceipt"
          label="票据号"
          width="120"
          align="center"
          fixed
        >
          <template slot-scope="scope">
            <div v-for="item in scope.row.batchReceipt">
              <span>{{ item.billNo }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="accountsRefund"
          label="应返金额"
          width="120"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="actuallyRefund"
          label="实返金额"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="refundTime"
          :formatter="columnDateFormat"
          label="返款时间"
          width="180"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="isReturn" label="返款状态" width="160" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.isReturn == 0" style="color: #ff4400">未返款</span>
            <span v-if="scope.row.isReturn == 1" style="color: #05cf59">已返款</span>
          </template>
        </el-table-column>

        <el-table-column prop="batchName" label="上传批次号" width="180" align="center">
        </el-table-column>
        <el-table-column prop="batchName" label="操作" align="center" width="300">
          <template slot-scope="scope">
            <el-button type="text" v-if="RefundButton" @click="handleRefund(scope.row)">
              <span v-show="scope.row.refundStatus == 0"> 填写返款信息</span>
              <span v-show="scope.row.isReturn == 0 && scope.row.refundStatus == 1">
                修改返款信息</span
              >
              <span v-show="scope.row.isReturn == 1"> 查看返款信息</span>
            </el-button>
            <el-button
              type="text"
              @click="handleCollection(scope.row)"
              v-show="scope.row.isReturn == 0 && scope.row.refundStatus == 1"
            >
              确认返款
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加返款信息 -->
    <div>
      <el-dialog
        :title="Refundtitle"
        :visible.sync="RefundRefund"
        width="600px"
        center="center"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div style="max-height: 600px; overflow: hidden; overflow-y: auto">
          <el-form
            ref="RefunForm"
            :model="RefunForm"
            :rules="RefunRules"
            label-width="160px"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="应返金额：" prop="content">
                  {{ RefunForm.accountsRefund }}元
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="实际返款金额：" prop="actuallyRefund">
                  <el-input
                    v-model="RefunForm.actuallyRefund"
                    placeholder="请输入实际返款金额"
                    style="width: 350px"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="返款人姓名：" prop="name">
                  <el-input
                    v-model="RefunForm.name"
                    placeholder="请输入返款人姓名"
                    style="width: 350px"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="返款人单位：" prop="company">
                  <el-input
                    v-model="RefunForm.company"
                    placeholder="请输入返款人单位"
                    style="width: 350px"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="是否返款给单位" prop="isCompany">
                  <el-radio-group v-model="RefunForm.isCompany">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="情况备注" prop="remarks">
                  <el-input
                    v-model="RefunForm.remarks"
                    style="width: 350px"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入具体情况备注"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="RefundRefund = false">取 消</el-button>
          <el-button
            type="primary"
            @click="handleConfirm"
            :disabled="isReturnId == 1"
            :loading="confirmLoading"
            >保 存
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { refundAllTask, refundCheck, refundAdd, refundInfo } from "@/api/ticket";
import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";
import axios from "axios";
import { formatPh, formatName } from "@/utils/validate";
import { queryPageButton } from "@/api/permission";

export default {
  name: "Refund",
  data() {
    var res = /^\d+$|^\d+[.]?\d+$/;

    const validateActuallyRefund = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入实际返款金额"));
      }
      if (res.test(value) == false) {
        callback(new Error("格式不正确，只能输入数字"));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入返款人姓名"));
      } else {
        callback();
      }
    };
    const validateCompany = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入返款人单位"));
      } else {
        callback();
      }
    };

    const validateIsCompany = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否返款给单位"));
      } else {
        callback();
      }
    };

    return {
      // 列表
      ticketList: [],

      billForm: {},
      RefunRules: {
        actuallyRefund: [
          {
            required: true,
            trigger: "blur",
            validator: validateActuallyRefund,
          },
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            validator: validateName,
          },
        ],
        company: [
          {
            required: true,
            trigger: "blur",
            validator: validateCompany,
          },
        ],
        isCompany: [
          {
            required: true,
            trigger: "blur",
            validator: validateIsCompany,
          },
        ],
      },
      loading: false,
      currentPage: 1,
      pageSize: 20,
      total: 0,
      taskName: "",
      searchTitle: "",
      searchIsReturn: null,
      searchBeginCollect: null,
      searchEndCollect: null,
      searchBeginReturn: null,
      searchEndReturn: null,
      searchBillNo: "",
      searchPhotographer: "",
      searchMixReturn: "",
      searchMaxReturn: "",
      searchBatchName: "",
      IsReturnList: [
        {
          label: "已返款",
          value: 1,
        },
        {
          label: "未返款",
          value: 0,
        },
      ],
      Refundtitle: "",
      DownloadLoading: false,
      confirmLoading: false,
      RefundRefund: false,
      RefunForm: {
        actuallyRefund: "",
        name: "",
        company: "",
        isCompany: null,
        remarks: "",
      },
      RefundId: null,
      isReturnId: null,
      refundStatus: null,

      // 权限按钮
      searchButton: false,
      DownloadButton: false,
      RefundButton: false,
    };
  },
  created() {
    this.query();
    this.queryButton();
  },
  methods: {
    queryButton() {
      const data = 134;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "DownloadButton") {
            this.DownloadButton = true;
          }
          if (button.code === "RefundButton") {
            this.RefundButton = true;
          }
        }
      });
    },
    query() {
      const data = {
        taskName: this.taskName,
        title: this.searchTitle,
        isReturn: this.searchIsReturn,
        beginCollect: this.formDateFormat(this.searchBeginCollect),
        endCollect: this.formDateFormat(this.searchEndCollect),
        beginReturn: this.formDateFormat(this.searchBeginReturn),
        endReturn: this.formDateFormat(this.searchEndReturn),
        billNo: this.searchBillNo,
        photographer: this.searchPhotographer,
        mixReturn: this.searchMixReturn,
        maxReturn: this.searchMaxReturn,
        batchName: this.searchBatchName,
      };
      this.ticketLoading = true;
      refundAllTask(data, this.currentPage, this.pageSize).then((resp) => {
        this.ticketList = resp.data.content;
        this.total = resp.data.totalElements;
        this.ticketLoading = false;
      });
    },

    // 添返款信息
    handleRefund(data) {
      // console.log(data);
      this.RefunForm = {};
      this.RefundRefund = true;
      this.RefundId = data.id;
      this.isReturnId = data.isReturn;
      this.refundStatus = data.refundStatus;
      this.RefunForm.accountsRefund = data.accountsRefund;
      this.RefunForm.collectType = data.collectType;
      this.Refundtitle = null;
      if (this.refundStatus == 0) {
        this.Refundtitle = "填写返款信息";
      } else if (this.refundStatus == 1) {
        this.Refundtitle = "修改返款信息";
        if (data.isReturn == 1) {
          this.confirmLoading = false;
          this.Refundtitle = "查看返款信息";
        }
        refundInfo(data.id).then((res) => {
          if (res.code == 0) {
            this.RefunForm = res.data;
          }
        });
      }
    },
    // 添返款信息确认
    handleConfirm() {
      this.$refs.RefunForm.validate((valid) => {
        if (valid) {
          let json = JSON.parse(JSON.stringify(this.RefunForm));
          let postData = json;
          postData.taskId = this.RefundId;
          postData.actuallyRefund = Number(this.RefunForm.actuallyRefund);
          this.confirmLoading = true;
          if (this.refundStatus == 0) {
            postData.status = 0;
          } else if (this.refundStatus == 1) {
            postData.status = 1;
          }
          refundAdd(postData).then((res) => {
            if (res.code == 0) {
              this.$notify({
                title: "温馨提示",
                message: res.message,
                type: "success",
              });
            } else {
              this.$notify({
                title: "警告",
                message: res.data.msg,
                type: "info",
              });
            }
            this.query();
            this.RefundRefund = false;
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },

    // 确认已收款
    handleCollection(data) {
      let postData = {
        id: data.id,
        collectType: data.collectType,
      };

      this.$confirm("是否确认返款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          refundCheck(postData)
            .then((resp) => {
              if (resp.code == 0) {
                this.$notify({
                  title: "温馨提示",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.data.msg,
                  type: "info",
                });
              }
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    // 取消
    handleCancel() {
      this.billForm = [];
      this.RefundRefundXP = false;
      this.RefundRefund = false;
    },
    // 导出
    handleDownload() {
      this.DownloadLoading = true;
      axios({
        method: "GET",
        url: "/api/csias/sys/batchReceipt/exportRefund",
        params: {
          taskName: this.taskName,
          title: this.searchTitle,
          isReturn: this.searchIsReturn,
          beginCollect: this.searchBeginCollect,
          endCollect: this.searchEndCollect,
          beginReturn: this.searchBeginReturn,
          endReturn: this.searchEndRetur,
          billNo: this.searchBillNo,
          photographer: this.searchPhotographer,
          mixReturn: this.searchMixReturn,
          maxReturn: this.searchMaxReturn,
          batchName: this.searchBatchName,
        },
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });
            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.DownloadLoading = false;
        },
        (err) => {
          this.DownloadLoading = false;
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      }
    },
  },
};
</script>

<style></style>
