import request from "@/utils/request";

//全部采集任务
export function queryAllTask(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/batchReceipt/queryAllTask?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

// 确认已收款
export function confirmReceipt(data) {
  return request({
    url:
      "/sys/batchReceipt/confirmReceipt?id=" +
      data.id +
      "&collectType=" +
      data.collectType,
    method: "get",
  });
}

//返款管理
export function refundAllTask(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/batchReceipt/refundAllTask?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

// 保存票据信息
export function batchReceiptAdd(data) {
  return request({
    url: "/sys/batchReceipt/add",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 确认返款
export function refundCheck(data) {
  return request({
    url: "/sys/refund/check?id=" + data.id + "&collectType=" + data.collectType,
    method: "get",
  });
}

// 查看返款信息
export function refundInfo(id) {
  return request({
    url: "/sys/refund/info?id=" + id,
    method: "get",
  });
}

// 保存返款信息
export function refundAdd(data) {
  return request({
    url: "/sys/refund/add",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 保存学校信息
export function schoolCollectsInformation(data) {
  
  return request({
    url: "/sys/schoolCollectsInformation/add",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}
